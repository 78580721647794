import React, { useEffect, useState } from 'react';

import { useLocation } from "react-router-dom"

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { PropsFromRedux } from '../containers/NavigationContainer';

import BulletinZoneContainer from '../containers/BulletinZoneContainer';
import NavigationTopBarContainer from '../containers/NavigationTopBarContainer';
import NavigationLeftSideBarContainer from '../containers/NavigationLeftSideBarContainer';
import FooterContainer from '../containers/FooterContainer';
import CookieConsentBannerContainer from '../containers/CookieConsentBannerContainer';
import PopupPropyAIContainer from '../containers/PopupPropyAIContainer';
import TrackingZone from './TrackingZone';
// import PopupPropySummitContainer from '../containers/PopupPropySummitContainer';

import {
  PROPY_BLUE,
  BASE_URL,
} from '../constants';

import PropyLogoHouse from '../assets/svg/propy-logo-house-only.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 1400,
      marginLeft: 'auto',
      marginRight: 'auto',
      minHeight: 'calc(100vh - 200px)',
      marginTop: theme.spacing(4),
    },
    rootMobile: {
      maxWidth: 1400,
      marginLeft: 'auto',
      marginRight: 'auto',
      minHeight: 'calc(100vh - 200px)',
      marginTop: 1,
    }
  }),
);

type NavigationProps = {
  children: React.ReactNode
}

const Navigation = (props: NavigationProps & PropsFromRedux) => {

    const { 
      children,
      isConsideredMedium,
      consentToCookies,
      dismissedPopupPropyAI,
      // dismissedPopupAIRealEstateSummit2023,
      // signedIn,
    } = props;

    const [autoHidePopup, setAutoHidePopup] = useState(false);

    const location = useLocation();

    const classes = useStyles();

    useEffect(() => {
      if(location && (['/home/', '/home'].indexOf(location?.pathname) === -1)) {
        setAutoHidePopup(true);
      } else {
        setAutoHidePopup(false);
      }
    }, [location])

    useEffect(() => {
      if(!consentToCookies) {
        try {
          //@ts-ignore
          if(!window.document.__defineGetter__) {
            Object.defineProperty(document, 'cookie', {
                get: function(){return ''},
                set: function(){return true},
            });
          } else {
            //@ts-ignore
            window.document.__defineGetter__("cookie", function() { return '';} );
            //@ts-ignore
            window.document.__defineSetter__("cookie", function() {} );
          }
        } catch (e) {
          console.error("Error encountered when attempting to block cookie functionality", e);
        }
      } else {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'hs-script-loader';
        script.async = true;
        script.defer = true;
        script.src = '//js.hs-scripts.com/4204561.js';

        document.head.appendChild(script);

        // Cleanup function to remove the script when the component unmounts or when consentToCookies changes to false
        return () => {
          document.head.removeChild(script);
        };
      }
    }, [consentToCookies])

    return (
        <>
          <BulletinZoneContainer
              // Icon={DescriptionIcon}
              img={PropyLogoHouse}
              catchLine="PROPY AI"
              catchLineColor={PROPY_BLUE}
              // date="February 1, 2023"
              body="Close faster and more securely with Propy AI"
              link={`${BASE_URL}/order`}
              externalLink={true}
              linkText="Drop a contract now"
          />
          <div className={isConsideredMedium ? classes.rootMobile : classes.root}>
              <NavigationTopBarContainer/>
              <NavigationLeftSideBarContainer/>
              {children}
          </div>
          {/* high zIndex to show above the intercom button */}
          <div style={{zIndex: 10000000000, position: 'relative'}}>
            {!consentToCookies && <CookieConsentBannerContainer />}
            {consentToCookies && !dismissedPopupPropyAI && !autoHidePopup && <PopupPropyAIContainer />}
            {/* {consentToCookies && !dismissedPopupAIRealEstateSummit2023 && !autoHidePopup && <PopupPropySummitContainer />} */}
          </div>
          {consentToCookies && <TrackingZone/>}
          <FooterContainer/>
        </>
    )
}

export default Navigation