import { connect, ConnectedProps } from 'react-redux';

import {
  dismissPopupPropyAI,
} from '../state/actions';

import PopupPropyAI from '../components/PopupPropyAI';

interface RootState {
  darkMode: boolean;
  isConsideredMobile: boolean;
  isConsideredMedium: boolean;
  signedIn: boolean;
}
  
const mapStateToProps = (state: RootState) => ({
  darkMode: state.darkMode,
  isConsideredMobile: state.isConsideredMobile,
  isConsideredMedium: state.isConsideredMedium,
  signedIn: state.signedIn,
})

const mapDispatchToProps = {
  dismissPopupPropyAI,
}
  
const connector = connect(mapStateToProps, mapDispatchToProps)
  
export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(PopupPropyAI)