import React, { useState, useEffect } from 'react';

import {
  animated,
  useSpring,
  useSprings,
  useInView,
} from '@react-spring/web'

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { PropsFromRedux } from '../containers/FeaturedListingsContainer';

import FloatingActionButton from './FloatingActionButton';
import LinkWrapper from './LinkWrapper';
import ListingCardUncontained from './ListingCardUncontained';

import {
  IListing
} from '../interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
      borderRadius: 8,
      // boxShadow: '0px 0px 20px 0px #00000026',
      padding: theme.spacing(0.5),
      minHeight: 800,
    },
    content: {
      // paddingTop: theme.spacing(12),
      // paddingBottom: theme.spacing(12),
      // paddingLeft: theme.spacing(4),
      // paddingRight: theme.spacing(4),
    },
    headingText: {
      zIndex: 2,
      position: 'relative',
      textAlign: 'center',
      marginBottom: theme.spacing(4)
    },
    headingTitle: {
      marginBottom: theme.spacing(1),
    },
    headingSubtitle: {

    },
    listedPropertiesButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(4),
    }
  }),
);

const FeaturedListings = (props: PropsFromRedux) => {
    const classes = useStyles();

    const [listings, setListings] = useState<IListing[]>([]);

    // const {
    //   isConsideredMobile,
    // } = props;

    useEffect(() => {
      let isMounted = true;
      fetch("https://api.propy.com/api/v3/properties/premium")
        .then(resp => resp.json())
        .then(response => {
          let curatedListings : IListing[] = [];

          let line3Limit = 2;
          let line3Count = 0;
          for(let entry of response.line3) {
            if(line3Limit > line3Count) {
              entry.imageUrls = entry.imageUrls.map((item: string) => `${item}?width=680&height=auto&scale=both&mode=crop`);
              curatedListings.push(entry);
              line3Count++;
            }
          }
          let line1Limit = 3;
          let line1Count = 0;
          for(let entry of response.line1) {
            if(line1Limit > line1Count) {
              entry.imageUrls = entry.imageUrls.map((item: string) => `${item}?width=680&height=auto&scale=both&mode=crop`);
              curatedListings.push(entry);
              line1Count++;
            }
          }
          let line2Limit = 2;
          let line2Count = 0;
          for(let entry of response.line2) {
            if(line2Limit > line2Count) {
              entry.imageUrls = entry.imageUrls.map((item: string) => `${item}?width=680&height=auto&scale=both&mode=crop`);
              curatedListings.push(entry);
              line2Count++;
            }
          }
          console.log({curatedListings})
          if(isMounted) {
            setListings(curatedListings);
          }
        })
      return () => {
        isMounted = false;
      };
    }, [])

    const [ref, isInView] = useInView({
      amount: 'any',
      once: true,
    })

    const titleSpring = useSpring({
      from: {
        opacity: 0,
        top: -25,
      },
      to: {
        opacity: isInView ? 1 : 0,
        top: isInView ? 0 : -25,
      },
      delay: 250
    })

    const [springs] = useSprings(
      listings.length + 1,
      (springIndex: number) => ({
        from: {
          opacity: 0,
          transform: 'translateY(-35px)',
        },
        to: {
          opacity: isInView ? 1 : 0,
          transform: isInView ? 'translateY(0px)' : 'translateY(-35px)',
        },
        delay: 1200 + (200 * springIndex),
      }),
      [isInView, listings]
    )

    return (
      <div className={classes.root} ref={ref}>
        <div className={classes.content}>
          <animated.div className={classes.headingText} style={titleSpring}>
            <Typography className={classes.headingTitle} style={{fontWeight: 'bold'}} variant="h3">
              Featured Listings
            </Typography>
            <Typography className={classes.headingSubtitle} variant="h5" component="h4">
              A curated collection of our favorite properties
            </Typography>
          </animated.div>
          <Grid container spacing={0}>
            {listings.map(
              (listing, index) => {
                if(index < 7) {
                  return (
                    <Grid key={`featured-listing-${index}`} item xs={12} sm={12} md={(index === 0) ? 8 : 4} lg={(index === 0) ? 6 : 3}>
                      <animated.div style={springs[index]}>
                        <ListingCardUncontained
                          listing={listing}
                        />
                      </animated.div>
                    </Grid>
                  )
                }
                return <></>
              }
            )}
          </Grid>
          {listings && (listings.length > 0) && 
            <animated.div style={springs[listings.length]} className={classes.listedPropertiesButtonContainer}>
              <LinkWrapper actionNameGA='lp_map_from_featured_listings' link="https://propy.com/map" external={true}>
                <FloatingActionButton
                  text="View More Listings"
                />
              </LinkWrapper>
            </animated.div>
          }
        </div>
      </div>
    )
};

export default FeaturedListings;