import React from 'react';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CloseIcon from '@mui/icons-material/Close';

import { animated, useSpring } from '@react-spring/web';

import { PropsFromRedux } from '../containers/PopupPropyAIContainer';

import PropyLogoHouse from '../assets/svg/propy-logo-house-only.svg';

import LinkWrapper from './LinkWrapper';

import { 
  PROPY_BLUE,
  BASE_URL,
} from '../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: '100%',
      maxWidth: '300px',
    },
    float: {
      padding: theme.spacing(2),
      position: 'fixed',
      borderRadius: 16,
      backgroundColor: 'white',
      boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14)',
    },
    floatMobile: {
      width: 'calc(100% - 30px)',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    floatDesktop: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      left: theme.spacing(3),
      width: 'calc(100% - 30px)',
      maxWidth: '350px',
    },
    spacer: {
      marginBottom: theme.spacing(0.5),
    },
    flexZone: {
      display: 'flex',
      alignItems: 'center',
    },
    logoZoneText: {
      fontWeight: 'bold',
    },
    linkStyles: {
      width: '100%',
      position: 'relative',
      textAlign: 'left',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    dismissButton: {
      cursor: 'pointer',
      position: 'absolute',
      right: '8px',
      top: '12px',
    }
  }),
);

function CookieConsentBanner(props: PropsFromRedux) {

    const {
      isConsideredMobile,
      dismissPopupPropyAI,
      // signedIn,
    } = props;

    const classes = useStyles();

    const bannerSpring = useSpring({
      from: {
        bottom: '-200px',
      },
      to: {
        bottom: isConsideredMobile ? '16px' : '24px',
      },
      delay: 500,
    })

    return (
      <div className={classes.root}>
        <animated.div style={bannerSpring} className={[classes.float, isConsideredMobile ? classes.floatMobile : classes.floatDesktop].join(" ")}>
          <div className={[classes.dismissButton, 'interactive-hover-opacity'].join(" ")} onClick={() => dismissPopupPropyAI()}>
            <CloseIcon style={{height: 18}}/>
          </div>
          <div className={[classes.flexZone, classes.spacer].join(" ")}>
            <img src={PropyLogoHouse} style={{height: 18,marginRight: 8}} alt="bulletin" />
            <Typography
              variant='body1'
              style={{fontWeight: 'bold'}}
            >
              <span className={classes.logoZoneText} style={{color: PROPY_BLUE}}>Propy AI</span>
            </Typography>
          </div>
          <Typography
            variant='h6'
            style={{fontWeight: 'bold'}}
            className={classes.spacer}
          >
            <span>Try the new Propy AI</span>
          </Typography>
          <Typography variant="body1" className={classes.spacer}>
            Drop an executed contract and get the closing started in seconds.
          </Typography>
          <div className={classes.flexZone} style={{color: PROPY_BLUE}}>
            <LinkWrapper actionNameGA='propy_ai_from_propy_ai_popup' link={`${BASE_URL}/order`} external={true} className={classes.linkStyles}>
              <Typography 
                variant="body1"
                style={{fontWeight: 'bold'}}
              >
                Try it now
              </Typography>
              <ArrowRightAltIcon/>
            </LinkWrapper>
          </div>
        </animated.div>
      </div>
    )
};

export default CookieConsentBanner;