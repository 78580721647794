import React, { useState } from 'react';

import { animated, useSpring, useInView } from '@react-spring/web'

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FloatingActionButton from './FloatingActionButton';
import AgentLeaderboardEntry from './AgentLeaderboardEntry';

import JuliethRomero from '../assets/img/agent-images/Julieth-Romero.jpg';
import AntonioBrucato from '../assets/img/agent-images/Antonio-Brucato.jpg';
import TameraBourne from '../assets/img/agent-images/Tamera-Bourne.jpg';
import SarahWallace from '../assets/img/agent-images/Sarah-Wallace.jpg';
import CarlosNavas from '../assets/img/agent-images/Carlos-Navas.jpg';
import ArianaLundstrom from '../assets/img/agent-images/Ariana-Lundstrom.jpg';
import ChrisMarion from '../assets/img/agent-images/Chris-Marion.jpg';
import ChristineGioia from '../assets/img/agent-images/Christine-Gioia.jpg';
import EfrenMartinezGuzman from '../assets/img/agent-images/Efren-Martinez-Guzman.jpg';
import JakeShort from '../assets/img/agent-images/Jake-Short.jpg';


import { PropsFromRedux } from '../containers/AgentLeaderboardContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
    },
    inner: {
      width: 1000,
    },
    headingText: {
      zIndex: 2,
      position: 'relative',
      textAlign: 'center',
      marginBottom: theme.spacing(4)
    },
    headingTitle: {
      marginBottom: theme.spacing(1),
    },
    headingSubtitle: {

    },
    showMoreButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    showMoreButton: {
      marginTop: 16,
    }
  }),
);

interface IAgentLeaderboard {
  initialDelay?: number
  hideHeading?: boolean
}

interface ILeaderboardDataRecord {
  rank: string
  name: string
  description: string
  amount: number | string
  profile_link: string | false
  image_link?: string
}

const leaderboardData: ILeaderboardDataRecord[] = [
  {
    rank: '1',
    name: 'Julieth Romero',
    description: 'La Rosa Realty Kissimmee',
    amount: '3450',
    profile_link: 'https://propy.com/4076391095',
    image_link: JuliethRomero
  },
  {
    rank: '2',
    name: 'Antonio Brucato P.A.',
    description: 'Dalton Wade Inc.',
    amount: '1250',
    profile_link: 'https://propy.com/7272518918',
    image_link: AntonioBrucato
  },
  {
    rank: '3',
    name: 'Tamera Bourne',
    description: 'eXp Realty',
    amount: '1160',
    profile_link: 'https://propy.com/9284446464',
    image_link: TameraBourne
  },
  {
    rank: '4',
    name: 'Sarah Wallace',
    description: 'Dalton Wade Inc.',
    amount: '1020',
    profile_link: 'https://propy.com/7272829050',
    image_link: SarahWallace
  },
  {
    rank: '5',
    name: 'Carlos Navas',
    description: 'Douglas Elliman',
    amount: '1010',
    profile_link: 'https://propy.com/9546393303',
    image_link: CarlosNavas
  },
  {
    rank: '6',
    name: 'Ariana Lundstrom',
    description: 'My Home Group',
    amount: '1000',
    profile_link: 'https://propy.com/agent/6666f7f287579715df79f969',
    image_link: ArianaLundstrom
  },
  {
    rank: '7',
    name: 'Chris Marion',
    description: 'Robert Slack LLC',
    amount: '1000',
    profile_link: 'https://propy.com/9412646650',
    image_link: ChrisMarion
  },
  {
    rank: '8',
    name: 'Christine Gioia',
    description: 'Compass',
    amount: '1000',
    profile_link: '',
    image_link: ChristineGioia
  },
  {
    rank: '9',
    name: 'Efren Martinez Guzman',
    description: 'eXp Realty',
    amount: '1000',
    profile_link: 'https://propy.com/agent/665a1a89740eca07fd0ed718',
    image_link: EfrenMartinezGuzman
  },
  {
    rank: '10',
    name: 'Jake Short',
    description: 'Better Homes and Gardens RE',
    amount: '1000',
    profile_link: 'https://propy.com/9282344332',
    image_link: JakeShort
  }
];

let topThreeColors : {[key: number]: string} = {
  0: "#e7c200",
  1: "#757575",
  2: "#b55200",
}

const AgentLeaderboard = (props: IAgentLeaderboard & PropsFromRedux) => {
    const classes = useStyles();

    const [showFullLeaderboard, setShowFullLeaderboard] = useState(false);

    const {
      initialDelay = 0,
      hideHeading = false,
    } = props;

    const [ref, isInView] = useInView({
      amount: 'any',
      once: true,
    })

    const titleSpring = useSpring({
      from: {
        opacity: 0,
        top: -25,
      },
      to: {
        opacity: isInView ? 1 : 0,
        top: isInView ? 0 : -25,
      },
      delay: initialDelay
    })

    const showMoreButtonSpring = useSpring({
      from: {
        opacity: 0,
        marginTop: '-35px',
      },
      to: {
        opacity: isInView ? 1 : 0,
        marginTop: isInView ? '0' : '-35px',
      },
      delay: initialDelay + (200 * 4),
    })


    return (
      <div className={classes.root} ref={ref}>
        <div className={classes.inner}>
          {!hideHeading &&
            <animated.div className={classes.headingText} style={titleSpring}>
              <Typography className={classes.headingTitle} style={{fontWeight: 'bold'}} variant="h3">
                Agent Leaderboard
              </Typography>
              <Typography className={classes.headingSubtitle} variant="h5" component="h4">
                Featuring this month's top performing agents on the Propy platform
              </Typography>
            </animated.div>
          }
          {/* <Grid style={{justifyContent: 'center'}} container columns={12} spacing={2}>
              {leaderboardData.map((leaderboardEntry, index) => 
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <AgentLeaderboardEntry
                    activateAnimation={isInView}
                    position={index + 1}
                    name={leaderboardEntry.name}
                    description={leaderboardEntry.description}
                    amount={leaderboardEntry.amount}
                    imageLink={leaderboardEntry.image_link}
                    profileLink={leaderboardEntry.profile_link}
                    baseDelay={initialDelay}
                    animationDelayMultiplier={index + 1}
                    positionCircleColorOverride={topThreeColors[index] ? topThreeColors[index] : undefined}
                  />
                </Grid>
              )}
          </Grid> */}
          <Grid style={{justifyContent: 'center'}} container columns={12} spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {leaderboardData.map((leaderboardEntry, index) => 
                index < 3 &&
                <div style={index > 0 ? {marginTop: 16} : {}}>
                  <AgentLeaderboardEntry
                    activateAnimation={isInView}
                    position={index + 1}
                    name={leaderboardEntry.name}
                    description={leaderboardEntry.description}
                    amount={leaderboardEntry.amount}
                    imageLink={leaderboardEntry.image_link}
                    profileLink={leaderboardEntry.profile_link}
                    baseDelay={initialDelay}
                    animationDelayMultiplier={index + 1}
                    positionCircleColorOverride={topThreeColors[index] ? topThreeColors[index] : undefined}
                    // borderColor={topThreeColors[index] ? topThreeColors[index] : undefined}
                  />
                </div>
              )}
              <>
                <>
                  {!showFullLeaderboard &&
                    <animated.div style={showMoreButtonSpring} className={classes.showMoreButtonContainer}>
                      <FloatingActionButton
                        className={classes.showMoreButton}
                        onClick={() => {setShowFullLeaderboard(true)}}
                        text="Show Full Leaderboard"
                        buttonColor="secondary"
                      />
                    </animated.div>
                  }
                </>
                {showFullLeaderboard && leaderboardData.map((leaderboardEntry, index) => 
                  index >= 3 &&
                  <div style={index >= 3 ? {marginTop: 16} : {}}>
                    <AgentLeaderboardEntry
                      activateAnimation={true}
                      position={index + 1}
                      name={leaderboardEntry.name}
                      description={leaderboardEntry.description}
                      amount={leaderboardEntry.amount}
                      imageLink={leaderboardEntry.image_link}
                      profileLink={leaderboardEntry.profile_link}
                      baseDelay={0}
                      animationDelayMultiplier={index}
                      positionCircleColorOverride={topThreeColors[index] ? topThreeColors[index] : undefined}
                      scaleFactor={0.6}
                    />
                  </div>
                )}
              </>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={6} lg={6}>
              {leaderboardData.map((leaderboardEntry, index) => 
                index >= 3 &&
                <div style={index > 3 ? {marginTop: 16} : {}}>
                  <AgentLeaderboardEntry
                    activateAnimation={isInView}
                    position={index + 1}
                    name={leaderboardEntry.name}
                    description={leaderboardEntry.description}
                    amount={leaderboardEntry.amount}
                    imageLink={leaderboardEntry.image_link}
                    profileLink={leaderboardEntry.profile_link}
                    baseDelay={initialDelay}
                    animationDelayMultiplier={index + 1}
                    positionCircleColorOverride={topThreeColors[index] ? topThreeColors[index] : undefined}
                    scaleFactor={0.6}
                  />
                </div>
              )}
            </Grid> */}
          </Grid>
        </div>
      </div>
    )
};

export default AgentLeaderboard;