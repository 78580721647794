import React from 'react';

import { animated, useSpring } from '@react-spring/web'

import { SvgIconComponent } from '@mui/icons-material';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import { PropsFromRedux } from '../containers/BulletinZoneContainer';

import LinkWrapper from './LinkWrapper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
      textAlign: 'center',
      padding: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(4),
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'black',
      color: 'white',
      alignItems: 'center',
    },
    icon: {
      marginRight: theme.spacing(1),
      opacity: 0.5,
    },
    catchLine: {
      fontWeight: 'bold',
      marginRight: theme.spacing(2),
    },
    date: {
      marginRight: theme.spacing(1),
    },
    body: {
      marginRight: theme.spacing(2),
    },
    link: {
      textDecoration: 'underline',
    },
    linkStyles: {
      width: '100%',
      position: 'relative',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
  }),
);

interface IBulletinZoneProps {
  Icon?: SvgIconComponent
  img?: string
  catchLine: string
  catchLineColor?: string
  date?: string
  body: string
  link: string
  externalLink?: boolean
  linkText: string
}

const BulletinZone = (props: IBulletinZoneProps & PropsFromRedux) => {
    const classes = useStyles();

    const {
      Icon,
      img,
      catchLine,
      catchLineColor,
      date,
      body,
      link,
      externalLink,
      linkText,
      isConsideredMedium,
    } = props;

    const bulletinZoneSpring = useSpring({
      from: {
        opacity: 0,
        transform: 'translateY(-25px)',
      },
      to: {
        opacity: 1,
        transform: 'translateY(0px)',
      },
      delay: 500,
    })

    return (
      <>
        {!isConsideredMedium &&
          <animated.div className={classes.root} style={bulletinZoneSpring}>
            <LinkWrapper link={link} external={externalLink} className={classes.linkStyles}>
              {Icon && 
                <Icon 
                  className={classes.icon}
                />
              }
              {img &&
                <img src={img} style={{height: 18,marginRight: 8}} alt="bulletin" />
              }
              <Typography
                variant='body1'
                style={{fontWeight: 'bold'}}
              >
                <span className={classes.catchLine} style={catchLineColor ? {color: catchLineColor} : {}}>{catchLine}</span>{date && <span className={classes.date}>{date}</span>}<span className={classes.body}>{body}</span><span style={catchLineColor ? {color: catchLineColor} : {}} className={classes.link}>{linkText}</span>
              </Typography>
              {linkText && <ArrowRightAltIcon style={catchLineColor ? {color: catchLineColor} : {}}/>}
            </LinkWrapper>
          </animated.div>
        }
        <div style={{height: 1}}></div>
      </>
    )
};

export default BulletinZone;