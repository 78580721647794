import React, { useEffect, useState } from 'react';

import { toast } from 'sonner'

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import {useDropzone} from 'react-dropzone'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudUploadCompleteIcon from '@mui/icons-material/CloudDone';
import CircularProgress from '@mui/material/CircularProgress';

import FloatingActionButton from './FloatingActionButton';

import { PropsFromRedux } from '../containers/DropContractUploadContainer';

import {
  storeAuthTokens,
  IdentityServerPropyAuthority
} from '../services/auth';

import { 
  UserService,
} from '../services/api';

import {
  BASE_URL,
  PROPY_BLUE
} from '../constants'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: 'center',
    },
    desktopPadding: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.5),
    },
    mobilePadding: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
    fileIcon: {
      width: 80,
      height: 80,
      opacity: 0.5,
      marginBottom: theme.spacing(1),
    },
    fileIconReadyForUpload:{
      width: 80,
      height: 80,
      opacity: 0.5,
      marginBottom: theme.spacing(1),
      color: PROPY_BLUE,
    },
    uploadingIcon: {
      width: 50,
      height: 50,
      opacity: 0.5,
      marginBottom: theme.spacing(1),
      color: PROPY_BLUE,
    },
    uploadButton: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    errorMessage: {
      marginTop: theme.spacing(1),
      color: 'red',
    },
    uploadIconsContainer: {
      width: '80px',
      height: '80px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    uploadSpinner: {
      width: '70px',
      height: '70px',
      position: 'absolute',
    },
    uploadButtonSubtitle: {
      marginTop: theme.spacing(2),
      fontWeight: 400,
    }
  }),
);

interface IDropContractUploadProps {
  
}

const getButtonText = (isUploading: boolean, isUploadComplete: boolean) => {
  let buttonText = "Next";
  if(isUploading) {
    buttonText = "Uploading...";
  }
  if(isUploadComplete) {
    buttonText = "Upload complete";
  }
  return buttonText;
}

const DropContractUpload = (props: IDropContractUploadProps & PropsFromRedux) => {
    const classes = useStyles();

    const [errorMessage, setErrorMessage] = useState<false | string>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isUploadComplete, setIsUploadComplete] = useState<boolean>(false);

    const {
      isConsideredMobile,
    } = props;

    const {
      acceptedFiles,
      fileRejections,
      getRootProps,
      getInputProps,
      isDragActive,
    } = useDropzone({
      accept: {
        'application/pdf': [],
      },
      maxFiles: 1,
    });

    useEffect(() => {
      setIsUploadComplete(false);
      setIsUploading(false);
      if(fileRejections?.[0]?.errors?.[0]?.message) {
        let overrideErrorMessage = fileRejections[0].errors[0].message;
        if(overrideErrorMessage === "Too many files") {
          overrideErrorMessage = "Please only select one contract, more can be added later."
        }
        if(overrideErrorMessage === "File type must be application/pdf") {
          overrideErrorMessage = "Please only select a .pdf file."
        }
        setErrorMessage(overrideErrorMessage)
      } else {
        setErrorMessage(false);
      }
    }, [fileRejections, acceptedFiles])

    const startUpload = async () => {
      setIsUploadComplete(false);
      setIsUploading(true);
      if(acceptedFiles && acceptedFiles?.length === 1) {
        let idServer = new IdentityServerPropyAuthority();
        let refreshTokensResponse = await idServer.refreshTokens() as any;
        let {
          refresh_token,
          access_token,
          expires_in,
          auth_time,
          profile,
          scope,
          token_type,
        } = refreshTokensResponse;
        await storeAuthTokens(access_token, refresh_token, expires_in, auth_time);
        await idServer.setUser({
          access_token,
          refresh_token,
          token_type,
          scope,
          profile,
          expires_at: (Number(auth_time) + expires_in).toString(),
        })
        try {
          let startExtractResponse = await UserService.uploadUserContract(acceptedFiles, acceptedFiles.map((acceptedFile: File) => acceptedFile.name), 'application/pdf');
          setIsUploading(false);
          if(startExtractResponse?.data?.documentJobs) {
            let documentJobEntries = Object.entries(startExtractResponse?.data?.documentJobs);
            let [docId, jobId] = documentJobEntries[0];
            if(docId && jobId) {
              setIsUploadComplete(true);
              let redirectUrl = `${BASE_URL}/order?externalAccessToken=${access_token}&docId=${docId}&jobId=${jobId}`
              window.location.href = redirectUrl;
              return;
            } else {
              setIsUploadComplete(true);
              toast.error("Something went wrong");
            }
          }
        } catch (e: any) {
          toast.error(e?.message ? e.message : e);
        }
      } else {
        toast.error("Valid contract not found or too many contracts selected");
      }
      setIsUploading(false);
      setIsUploadComplete(false);
    }

    const {ref, ...rootProps} = getRootProps()

    return (
      <div>
        <Paper 
          style={{
            border: isDragActive ? `2px dashed ${PROPY_BLUE}` : `2px ${acceptedFiles?.length > 0 ? `solid ${PROPY_BLUE}` : 'dashed #dcdcdc'}`,
            cursor: (acceptedFiles && (acceptedFiles?.length > 0)) ? 'auto' : 'pointer',
          }}
          {...rootProps}
          ref={ref}
          className={[classes.root, isConsideredMobile ? classes.mobilePadding : classes.desktopPadding].join(" ")}
          {...getRootProps({
            onClick: (event: any) => {
              if(acceptedFiles && (acceptedFiles?.length > 0)) {
                event.preventDefault();
                event.stopPropagation();
              }
            },
          })}
        >
          <input {...getInputProps()} />
          {(!acceptedFiles || (acceptedFiles?.length === 0)) &&
            <>
              <FilePresentIcon className={classes.fileIcon} />
              <Typography>Drop an executed contract or <b style={{color: PROPY_BLUE, cursor: 'pointer'}}>choose a file</b> to upload.</Typography>
            </>
          }
          {acceptedFiles && (acceptedFiles?.length === 1) &&
            <>
              {(!isUploading && !isUploadComplete) &&
                <>
                  <FilePresentIcon className={classes.fileIconReadyForUpload} />
                  <Typography>Propy AI is ready to read your contract.</Typography>
                </>
              }
              {(isUploading && !isUploadComplete) &&
                <>
                  <div className={classes.uploadIconsContainer}>
                    <CircularProgress sx={{
                      '& .MuiCircularProgress-svg': {
                        width: 70, height: 70,
                      }}} size={70} style={{width: 70, height: 70, position: 'absolute'}} thickness={1}/>
                    <CloudUploadIcon className={classes.uploadingIcon} />
                  </div>
                  <Typography>Uploading your contract...</Typography>
                </>
              }
              {(isUploadComplete && !isUploading) &&
                <>
                  <CloudUploadCompleteIcon className={classes.fileIconReadyForUpload} />
                  <Typography>Your contract has been uploaded, redirecting...</Typography>
                </>
              }
            </>
          }
          {errorMessage && <Typography className={classes.errorMessage}>{errorMessage}</Typography>}
          {acceptedFiles && (acceptedFiles?.length === 1) &&
            <>
              <FloatingActionButton disabled={isUploading || isUploadComplete} onClick={() => startUpload()} className={[classes.uploadButton].join(' ')} text={getButtonText(isUploading, isUploadComplete)} buttonColor="primary" />
              {(!isUploading && !isUploadComplete) && 
                <Typography variant="subtitle2" className={classes.uploadButtonSubtitle}>To select a different contract, <b style={{color: PROPY_BLUE, cursor: 'pointer'}} {...rootProps}>click here</b>.</Typography>
              }
            </>
          }
        </Paper>
      </div>
    )
};

export default DropContractUpload;